import { useEffect, useState } from "react";
import ConvertSize from "../functions/ConvertSize";
import FileIcon from "../static/images/file_big_icon.svg";

export default function File(props) {
  const [size, setSize] = useState();
  const [extension, setExtension] = useState();

  useEffect(() => {
    ConvertSize(props.data.file_size, setSize, setExtension);
  }, [props.data.file_size]);

  return (
    <div className="file-top-wrapper">
      <div className="file-container">
        <img src={FileIcon} alt="File" />
        <div className="line"></div>
        <div className="file-bottom-part">
          <div>
            <p>{props.data.file_name}</p>
            <p>
              {size} {extension}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
