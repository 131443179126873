export default function ConvertSize(bytes, setSize, setExtension) {
  let kilobytes = bytes / 1024;
  let megabytes = bytes / (1024 * 1024);
  let gigabytes = bytes / (1024 * 1024 * 1024);

  if (gigabytes >= 1) {
    setSize(gigabytes.toFixed(2));
    setExtension("GB");
  } else if (megabytes >= 1) {
    setSize(megabytes.toFixed(2));
    setExtension("MB");
  } else {
    setSize(kilobytes.toFixed(2));
    setExtension("KB");
  }
}
