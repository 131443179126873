import { useEffect, useState } from "react";
import PlusIconGray from "../static/images/plus_icon.png";
import MinusIconGray from "../static/images/minus_icon.png";
import SettingsIcon from "../static/images/settings_icon.svg";
import XIcon from "../static/images/x_icon.svg";
import Info from "../static/images/info_icon.svg";
import EyeIcon from "../static/images/eye_icon.svg";
import EyeIcon2 from "../static/images/password_scnd_icon.png";
import DownArrow from "../static/images/down_arrow.png";
import PlusIcon from "../static/images/plus_icon.svg";
import DarkLogo from "../static/images/dark_logo.svg";
import AddedFileFolder from "./AddedFileFolder";
import { Tooltip } from "react-tooltip";
import ConvertSize from "../functions/ConvertSize";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ContactMail from "./ContactMail";
import ProgressBar from "./ProgressBar";

export default function MainButton() {
  console.log(process.env.REACT_APP_API_URL);
  const [forms, toggleForms] = useState("logo");
  const [settings, toggleSettings] = useState(true);
  let timer;
  let num = 0;
  let btnState = "hold";
  let [filesAndFolders, setFilesFolders] = useState({ folders: [], files: [] });
  let [totalFiles, setTotalFiles] = useState(0);
  let [totalSize, setTotalSize] = useState(0);
  let [size, setSize] = useState(0);
  let [extension, setExtension] = useState("");
  let [formType, setFormType] = useState("email");
  let [expiration, setExpiration] = useState(7);
  let [expirationDate, setExpirationDate] = useState();
  let [expirationError, setExpirationError] = useState("");
  let [passwordError, setPasswordError] = useState("");
  let [password, setPassword] = useState("");
  let [hasSender, setSender] = useState(false);
  let [showPassword, toggleVisibility] = useState(false);
  let [container_id, setContainerId] = useState("");
  let [contactMails, setContactMails] = useState([]);
  let [totalFilesLength, setTotalFilesLength] = useState(0);
  let [totalFilesSent, setTotalFilesSent] = useState(0);
  let [progress, setProgress] = useState(0);

  useEffect(() => {
    setInterval(() => {
      document.querySelectorAll(".tooltip-part").forEach((part) => {
        part.classList.toggle("not-visible");
      });
      return;
    }, 4000);
  }, []);

  function logoDown() {
    timer = setInterval(() => {
      num++;

      if (num > 50) {
        btnState = "hold";
        document.querySelector(".folder-input").click();
        clearInterval(timer);
      } else {
        btnState = "click";
      }
    }, 10);
  }

  function logoUp() {
    if (btnState !== "hold") {
      document.querySelector(".file-input").click();
      btnState = "click";
    }

    num = 0;
    clearInterval(timer);
    setExpirationDate(calculateExpirationDate(expiration));
  }

  function viewUploadedFolder(e) {
    setTotalFilesLength((totalFilesLength += e.target.files.length));
    if (e.target.files.length !== 0) {
      for (const f of e.target.files) {
        setTotalSize((totalSize += f.size));
      }

      setTotalFiles((totalFiles += e.target.files.length));
      setFilesFolders((prevState) => ({
        ...prevState,
        folders: [
          ...prevState.folders,
          {
            folder: [
              e.target.files[0].webkitRelativePath.slice(
                0,
                e.target.files[0].webkitRelativePath.indexOf("/")
              ),
              e.target.files,
            ],
          },
        ],
      }));
      toggleForms("forms");

      ConvertSize(totalSize, setSize, setExtension);
    }
  }

  function viewUploadedFile(e) {
    setTotalFilesLength((totalFilesLength += 1));
    if (e.target.files.length !== 0) {
      setTotalSize((totalSize += e.target.files[0].size));

      setTotalFiles((totalFiles += e.target.files.length));
      setFilesFolders((prevState) => ({
        ...prevState,
        files: [...prevState.files, { files: e.target.files }],
      }));
      toggleForms("forms");

      ConvertSize(totalSize, setSize, setExtension);
    }
  }

  function switchSettings() {
    // If password is not set, clear password input on close
    if (password === "") {
      document.querySelector(".settings-password").value = "";
    }

    if (settings === true) {
      toggleSettings(false);
      document.querySelector(".settings-form").style = "transform: scaleY(1);";
    } else {
      toggleSettings(true);
      document.querySelector(".settings-form").style = "transform: scaleY(0);";
    }
  }

  function toggleEmailLink(e) {
    document.querySelector(".email-tag").classList.remove("active-p");
    document.querySelector(".link-tag").classList.remove("active-p");

    if (e.target.textContent === "Email") {
      document.querySelector(".email-tag").classList.add("active-p");
      setFormType("email");
    } else {
      document.querySelector(".link-tag").classList.add("active-p");
      setFormType("link");
    }
  }

  // Remove file or folder
  function removeFileFolder(params) {
    let updatedFolders;
    let updatedFiles;

    if (params[1] === "file") {
      updatedFolders = [...filesAndFolders.folders];
      const fileToRemove = filesAndFolders.files.find(
        (file) => file.files[0].name === params[0]
      );
      updatedFiles = filesAndFolders.files.filter(
        (file) => file.files[0].name !== params[0]
      );
      setTotalFiles(totalFiles - 1);
      setTotalSize(totalSize - fileToRemove.files[0].size);
      ConvertSize(
        totalSize - fileToRemove.files[0].size,
        setSize,
        setExtension
      );
    } else {
      let totalSizeToRemove = 0;
      updatedFolders = filesAndFolders.folders.filter(
        (folder) => folder.folder[0] !== params[0]
      );
      updatedFiles = [...filesAndFolders.files];
      setTotalFiles(
        totalFiles -
          filesAndFolders.folders.find(
            (folder) => folder.folder[0] === params[0]
          ).folder[1].length
      );

      // Update total size of folders
      Object.entries(
        filesAndFolders.folders.find((folder) => folder.folder[0]).folder[1]
      ).forEach((f) => {
        totalSizeToRemove += f[1].size;
      });

      setTotalSize(totalSize - totalSizeToRemove);
      ConvertSize(totalSize - totalSizeToRemove, setSize, setExtension);
    }

    setFilesFolders({ folders: updatedFolders, files: updatedFiles });
  }

  // Change expiration
  function changeExpiration(e) {
    e.preventDefault();

    if (e.target.value === "") {
      setExpirationError("You must insert expiration days");
      document.querySelector(".days-num-wrapper").style =
        "border: 1px solid #ff0000";
      document.querySelector(".days-placeholder").style = "color: #ff0000";
      document.querySelector(".submit-form-btn").style =
        "opacity: 0.5; pointer-events: none";
      document.querySelector(".settings-x-icon").style =
        "opacity: 0.5; pointer-events: none";
    } else if (
      parseInt(e.target.value) > 14 ||
      parseInt(e.target.value) === 0
    ) {
      setExpirationError("Number can't be higher than 14 and less then 1");
      document.querySelector(".days-num-wrapper").style =
        "border: 1px solid #ff0000";
      document.querySelector(".days-placeholder").style = "color: #ff0000";
      document.querySelector(".submit-form-btn").style =
        "opacity: 0.5; pointer-events: none";
      document.querySelector(".settings-x-icon").style =
        "opacity: 0.5; pointer-events: none";
    } else {
      setExpirationError("");
      setExpiration(Number(e.target.value));
      document.querySelector(".days-num-wrapper").style = "border: none";
      document.querySelector(".days-placeholder").style = "color: #7F8183";
      document.querySelector(".submit-form-btn").style =
        "opacity: 1; pointer-events: all";
      document.querySelector(".settings-x-icon").style =
        "opacity: 1; pointer-events: all";
    }
  }

  if (forms === "forms" && totalFiles === 0) {
    window.location.reload();
  }

  // Calculate expireation date
  function calculateExpirationDate(days) {
    var today = new Date();
    var nextWeek = new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
    var month = nextWeek.getMonth() + 1;
    var day = nextWeek.getDate();
    var year = nextWeek.getFullYear();
    return (
      (month < 10 ? "0" + month : month) +
      "/" +
      (day < 10 ? "0" + day : day) +
      "/" +
      year
    );
  }

  // Submit settings
  function submitSettings() {
    setExpirationDate(calculateExpirationDate(expiration));
    toggleSettings(true);
    document.querySelector(".settings-form").style = "transform: scaleY(0);";
    setPassword(document.querySelector(".settings-password").value);
  }
  const notifyError = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      theme: "dark",
      closeOnClick: false,
    });

  // Send files and folders
  function sendFiles() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      document.querySelector(".user-email").value !== "" &&
      (document.querySelector(".contacts-mails-wrapper").innerHTML !== "" ||
        emailRegex.test(document.querySelector(".contacts-email").value))
    ) {
      // Show animation
      document.querySelector(".form-right").style =
        "transform: translateX(160px); opacity: 0;";
      document.querySelector(".form-left").style =
        "transform: translateX(-160px); opacity: 0;";

      setTimeout(() => {
        toggleForms("loading");
      }, 200);

      const formData = new FormData();
      let result = "";
      const characters = "abcdefghijklmnopqrstuvwxyz";
      let expires = document.querySelector(".expiration-input").value;
      let password =
        document.querySelector(".settings-password").value === ""
          ? "none"
          : document.querySelector(".settings-password").value;
      let is_self_destruct = document.querySelector(
        ".self-destruct-checkbox"
      ).checked;
      let is_password_encrypted = password === "none" ? false : true;

      // Create random id
      for (let i = 0; i < 5; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }

      // Create container
      formData.append("transaction_id", result);
      formData.append("expires_in", expires);
      formData.append("password", password);
      formData.append("is_self_destruct", is_self_destruct);
      formData.append("is_password_encrypted", is_password_encrypted);

      fetch(process.env.REACT_APP_API_URL + "/create-container", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setContainerId(data.container_hash);

          // Send email

          fetch(process.env.REACT_APP_API_URL + "/process-email", {
            method: "POST",
            body: JSON.stringify({
              transaction_id: result,
              to_email: document.querySelector(".contacts-email").value,
              from_email: document.querySelector(".user-email").value,
              transaction_message: document.querySelector(".message").value,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then(data)
            .catch((error) => console.error(error));
        })
        .catch((error) => {});

      // Send files
      const filePromises = filesAndFolders.files.map((f) => {
        const fileData = new FormData();
        fileData.append("file_binary", f.files[0]);
        fileData.append("transaction_id", result);

        return Promise.resolve(
          fetch(process.env.REACT_APP_API_URL + "/upload", {
            method: "POST",
            body: fileData,
          })
        ).then((response) => {
          if (response.ok) {
            setTotalFilesSent((totalFilesSent += 1));
            setProgress((totalFilesSent / totalFilesLength) * 100);
          }
        });
      });

      // Send folders
      const folderPromises = [];
      for (const f of filesAndFolders.folders) {
        for (const fo of f.folder[1]) {
          const fileData = new FormData();
          fileData.append("file_binary", fo);
          fileData.append("transaction_id", result);

          folderPromises.push(
            fetch(process.env.REACT_APP_API_URL + "/upload", {
              method: "POST",
              body: fileData,
            }).then((response) => {
              setTotalFilesSent((totalFilesSent += 1));
              setProgress((totalFilesSent / totalFilesLength) * 100);
            })
          );
        }
      }

      Promise.all([...filePromises, ...folderPromises])
        .then(() => {
          setTimeout(() => {
            toggleForms("doneUploading");
          }, 600);
        })
        .catch((error) => {
          // Handle error
        });
    } else {
      notifyError("Please insert you email and your contacts' email");
    }
  }

  // Add/remove password
  function changePassword(e) {
    if (e.target.value.length < 4 && e.target.value !== "") {
      setPasswordError("4 characters minimum.");
      document.querySelector(".submit-form-btn").style =
        "opacity: 0.5; pointer-events: none";
      document.querySelector(".settings-x-icon").style =
        "opacity: 0.5; pointer-events: none";
    } else {
      setPasswordError("");
      document.querySelector(".submit-form-btn").style =
        "opacity: 1; pointer-events: all";
      document.querySelector(".settings-x-icon").style =
        "opacity: 1; pointer-events: all";
    }
  }

  // Toggle sender
  function toggleSender() {
    if (hasSender === true) {
      setSender(false);
    } else {
      setSender(true);
    }
  }

  // Toggle password visibility
  function togglePasswordVisibility() {
    if (showPassword === true) {
      toggleVisibility(false);
    } else {
      toggleVisibility(true);
    }
  }

  // Set users email into cooke
  function setEmail(e) {
    document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "email=" + e.target.value;
  }

  // Get a user email from cookies
  function getCookieValue(name) {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split("=");
      if (cookie[0] === name) {
        return cookie[1];
      }
    }
    return null;
  }

  // Add contacts email
  function addContactsEmail(e) {
    if (e.key === "Enter") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (
        emailRegex.test(document.querySelector(".contacts-email").value) &&
        !contactMails.includes(document.querySelector(".contacts-email").value)
      ) {
        setContactMails((oldArray) => [
          ...oldArray,
          document.querySelector(".contacts-email").value,
        ]);
        document.querySelector(".contacts-email").value = "";
      } else {
        notifyError("That email was not vaild or already exists.");
      }
    }
  }

  const email = getCookieValue("email");

  return (
    <section className="main-btn-section clear">
      <input
        // accept=".mp3,.wav,.aac,.flac,.wma,.aiff,.ogg,.dsd,.alac,.mp4,.ac3,.au,.m4a,.opus"
        onChange={viewUploadedFolder}
        className="folder-input"
        directory=""
        webkitdirectory=""
        type="file"
      />
      <input
        // accept=".mp3,.wav,.aac,.flac,.wma,.aiff,.ogg,.dsd,.alac,.mp4,.ac3,.au,.m4a,.opus"
        onChange={viewUploadedFile}
        className="file-input"
        type="file"
        capture="camera"
      />

      {forms === "forms" ? (
        <div className="forms-container">
          <div className="form-left">
            <div className="form-top-row">
              <span className="num-of-files">
                {totalFiles} {totalFiles === 1 ? "file" : "files"}
              </span>
              <span className="files-size">
                ({size} {extension})
              </span>
              <a data-tooltip-id="my-tooltip" className="tooltip-plus-btn">
                <img src={PlusIcon} alt="Add" />
              </a>
              <Tooltip
                closeOnEsc={true}
                noArrow={true}
                clickable={true}
                content={
                  <div className="tooltip-content">
                    <div
                      onClick={(e) => {
                        document.querySelector(".file-input").click();
                      }}
                    >
                      <span className="tooltip-plus">+</span> <span>Files</span>
                    </div>
                    <div
                      onClick={(e) => {
                        document.querySelector(".folder-input").click();
                      }}
                    >
                      <span className="tooltip-plus">+</span>{" "}
                      <span>Folders</span>
                    </div>
                  </div>
                }
                openOnClick={true}
                id="my-tooltip"
                className="form-tooltip"
              />
            </div>
            <div className="files-wrapper">
              {Object.entries(filesAndFolders)[0][1].map((fo, i) => {
                return (
                  <AddedFileFolder
                    key={i}
                    isFile={false}
                    file={fo.folder[1]}
                    fileType="folder"
                    folderName={fo.folder[0]}
                    removeFileFolder={removeFileFolder}
                  ></AddedFileFolder>
                );
              })}
              {Object.entries(filesAndFolders)[1][1].map((fo, i) => {
                return (
                  <AddedFileFolder
                    key={i}
                    isFile={true}
                    file={fo}
                    fileType="file"
                    fileName={fo.files[0].name}
                    fileSize={fo.files[0].size}
                    removeFileFolder={removeFileFolder}
                  ></AddedFileFolder>
                );
              })}
            </div>
          </div>
          <div className="form-right">
            <div className="right-form-top-row">
              <p
                className="email-tag active-p"
                onClick={(e) => {
                  toggleEmailLink(e);
                }}
              >
                Email
              </p>
              <p
                className="link-tag inactive-p"
                onClick={(e) => {
                  toggleEmailLink(e);
                }}
              >
                Link
              </p>
            </div>
            {formType === "email" ? (
              <form>
                <div className="form-input-wrapper email-input-wrapper">
                  <input
                    onInput={(e) => {
                      setEmail(e);
                    }}
                    placeholder="Your email"
                    type="email"
                    defaultValue={email}
                    className="user-email"
                  />
                  <span onClick={toggleSender} className="show-hide-sender">
                    {hasSender !== true ? (
                      <img src={PlusIconGray} alt="Add"></img>
                    ) : (
                      <img src={MinusIconGray} alt="Remove"></img>
                    )}
                  </span>
                </div>
                {hasSender === true ? (
                  <div className="form-input-wrapper">
                    <input placeholder="Sender's name" type="text" />
                    <a
                      data-tooltip-place="right"
                      data-tooltip-id="sender-tooltip"
                      className="tooltip-plus-btn"
                    >
                      <img src={Info} alt="Info" />
                    </a>
                    <Tooltip
                      className="form-input-tooltip-sender"
                      id="sender-tooltip"
                      content="If you want, you can customize your sender name so that your recipients can identify you more easily. e.g: Will Smith"
                      noArrow={true}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="contacts-mails-wrapper">
                  {contactMails.map((c, i) => {
                    return <ContactMail key={i} mail={c}></ContactMail>;
                  })}
                </div>
                <input
                  className="contacts-email"
                  placeholder="Your contacts’ email"
                  type="email"
                  onKeyUp={(e) => {
                    addContactsEmail(e);
                  }}
                />
                <input className="" placeholder="Subject" type="text" />
                <textarea className="message" placeholder="Message"></textarea>
              </form>
            ) : (
              <form>
                <div className="form-input-wrapper">
                  <input
                    onInput={(e) => {
                      setEmail(e);
                    }}
                    defaultValue={email}
                    placeholder="Your email"
                    type="email"
                  />
                  <a
                    data-tooltip-place="right"
                    data-tooltip-id="email-tooltip"
                    className="tooltip-plus-btn"
                  >
                    <img src={Info} alt="Info" />
                  </a>
                  <Tooltip
                    className="form-input-tooltip-email"
                    id="email-tooltip"
                    content="Your email address is required for security reasons. This will also alow us to send you a copy of the link and notify you whe someone downloads your files."
                    noArrow={true}
                  />
                </div>
                <div className="form-input-wrapper">
                  <input
                    className="title-input"
                    placeholder="Title"
                    type="text"
                  />
                  <a
                    data-tooltip-place="right"
                    data-tooltip-id="title-tooltip"
                    className="tooltip-plus-btn"
                  >
                    <img src={Info} alt="Info" />
                  </a>
                  <Tooltip
                    className="form-input-tooltip"
                    id="title-tooltip"
                    content="If you wish, you can customize the title of your transfer to make it more easily to identifiable."
                    noArrow={true}
                  />
                </div>
                <div className="from-input">
                  <span>fromsoundproof.com/</span>
                  <input type="text" />
                  <a
                    data-tooltip-place="right"
                    data-tooltip-id="from-tooltip"
                    className="tooltip-plus-btn"
                  >
                    <img src={Info} alt="Info" />
                  </a>
                  <Tooltip
                    className="form-input-tooltip-from"
                    id="from-tooltip"
                    content="If you wish, you can customize the link of your tranfer to make it more easily identifiable. e.g: https://fromsoundproof.com/Project"
                    noArrow={true}
                  />
                </div>
                <div className="link-row">
                  <p className="require-email">
                    Require email to download files
                  </p>
                  <input className="link-checkbox" type="checkbox" />
                </div>
              </form>
            )}
            <div className="right-form-bottom-row">
              {settings === true ? (
                <img
                  onClick={switchSettings}
                  src={SettingsIcon}
                  alt="Close settings"
                />
              ) : (
                <img
                  className="settings-x-icon"
                  onClick={expirationError === "" ? switchSettings : undefined}
                  src={XIcon}
                  alt="Settings"
                />
              )}
              <div>
                {settings === true ? (
                  <div>
                    <p>Expires on {expirationDate}</p>
                    <p>
                      {password !== "" ? "Password added" : "No password added"}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {settings === true ? (
                <a className="submit-form-btn" onClick={sendFiles} href="#">
                  Send
                </a>
              ) : (
                <a
                  className="submit-form-btn"
                  onClick={
                    expirationError === "" && passwordError === ""
                      ? submitSettings
                      : undefined
                  }
                  href="#"
                >
                  Done
                </a>
              )}
            </div>
            <div className="settings-form">
              <h6>Transfer settings</h6>
              <div className="form-row">
                <p>
                  Expiration <br />{" "}
                  <span className="expiration-error">{expirationError}</span>
                </p>
                <div className="days-num-wrapper">
                  <input
                    className="expiration-input"
                    onInput={(e) => {
                      changeExpiration(e);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "-") {
                        e.preventDefault();
                      }
                    }}
                    defaultValue={expiration}
                    type="number"
                  />
                  <div className="days-num-separator"></div>
                  <p className="days-placeholder">days</p>
                </div>
              </div>
              <div className="settings-line-separator"></div>
              <div className="form-row">
                <p>
                  Password <br />{" "}
                  <span className="password-error">{passwordError}</span>
                </p>
                <div className="settings-pass-wrapper">
                  <input
                    onInput={(e) => {
                      changePassword(e);
                    }}
                    placeholder="Add password"
                    className="settings-password"
                    type={showPassword === false ? "password" : "text"}
                    defaultValue={password}
                  />
                  <img
                    onClick={togglePasswordVisibility}
                    src={showPassword === false ? EyeIcon : EyeIcon2}
                    alt="Eye"
                  />
                </div>
              </div>
              <div className="settings-line-separator"></div>
              <div className="form-row">
                <p>Let me know when someone downloads</p>
                <input
                  className="settings-checkbox let-know-checkbox"
                  type="checkbox"
                />
              </div>
              <div className="form-row">
                <p>Self destruct after download</p>
                <input
                  className="settings-checkbox self-destruct-checkbox"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </div>
      ) : forms === "loading" || forms === "doneUploading" ? (
        <div className="loading-box">
          <img src={DarkLogo} alt="Logo" />
          <h5>
            {forms === "doneUploading" ? (
              "Your transfer has been sent"
            ) : (
              <ProgressBar percentage={progress} />
            )}
          </h5>
          {forms === "doneUploading" ? (
            <div className="finished-part">
              <p>
                If you like using Soundproof, don't hesitate to leave us a
                (good) rating and <a href="#">share your opinion</a>. Thank you
                very much!
              </p>
              <div className="view-transfer-btn">
                <Link to={`/upload/${container_id}`}>View transfer</Link>
                <img
                  data-tooltip-id="transfer-tooltip"
                  src={DownArrow}
                  alt="Arrow"
                />
              </div>
              <Tooltip
                closeOnEsc={true}
                noArrow={true}
                clickable={true}
                content={
                  <div className="transfer-tooltip-content">
                    <div>
                      <a href="/">Start new transfer</a>
                    </div>
                    <div>
                      <a href="#">Discover our plans</a>
                    </div>
                  </div>
                }
                openOnClick={true}
                id="transfer-tooltip"
                className="transfer-tooltip"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="pulse-container">
          <div className="logo-tooltip">
            <p>
              <span className="tooltip-part">Click to send your files</span>
              <span className="tooltip-part not-visible">
                Long press to send your folders
              </span>
            </p>
            <div className="tooltip-arrow"></div>
          </div>
          <div
            onMouseDown={logoDown}
            onMouseUp={logoUp}
            className="pulse-animation"
          ></div>
        </div>
      )}
    </section>
  );
}
