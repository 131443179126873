import React, { useState, useEffect } from "react";

const ProgressBar = ({ percentage }) => {
  const [barWidth, setBarWidth] = useState(0);

  useEffect(() => {
    setBarWidth(percentage);
  }, [percentage]);

  return (
    <div className="progress-bar">
      <div
        className="bar"
        style={{ width: `${barWidth}%`, transition: "width 1s" }}
      ></div>
      <p className="percentage-num">{Math.floor(percentage)} %</p>
    </div>
  );
};

export default ProgressBar;
