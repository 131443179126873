import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DarkLogo from "../static/images/dark_logo.svg";
import EyeIcon from "../static/images/eye_icon.svg";
import EyeIcon2 from "../static/images/password_scnd_icon.png";
import { toast } from "react-toastify";
import File from "../components/File";
import FileIcon from "../static/images/file_big_icon.svg";
import ConvertSize from "../functions/ConvertSize";
import CountDown from "../components/CountDown";

export default function TransferingPage() {
  const { id } = useParams();
  let [showPassword, toggleVisibility] = useState(false);
  let [hasPassword, togglePassword] = useState(true);
  let [downloadWithPassword, passwordDownload] = useState();
  let [data, setData] = useState(null);
  let [size, setSize] = useState(null);
  let [extension, setExtension] = useState(null);
  let [password, setPassword] = useState(null);

  const notifyError = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      theme: "dark",
      closeOnClick: false,
    });

  useEffect(() => {
    setInterval(() => {
      document.querySelectorAll(".tooltip-part").forEach((part) => {
        part.classList.toggle("not-visible");
      });
      return;
    }, 4000);
  }, []);

  // Toggle password visibility
  function togglePasswordVisibility() {
    if (showPassword === true) {
      toggleVisibility(false);
    } else {
      toggleVisibility(true);
    }
  }

  // Check password
  function checkPassword() {
    fetch(process.env.REACT_APP_API_URL + "/container/" + id + "/info", {
      method: "POST",
      body: JSON.stringify({
        password: document.querySelector(".settings-password").value,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.detail === "Invalid password.") {
          notifyError(data.detail);
        } else {
          setPassword(document.querySelector(".settings-password").value);
          togglePassword(false);
          setData(data);
        }
      })
      .catch((error) => {
        // Handle error
      });
  }

  // Call first API
  const fetchData = useCallback(() => {
    fetch(process.env.REACT_APP_API_URL + "/container/" + id + "/info")
      .then((response) => {
        if (!response.ok) {
        }
        return response.json();
      })
      .then((data) => {
        if (data.detail === "Container is password protected.") {
          // Have password
          togglePassword(true);
          passwordDownload(true);
        } else {
          // Dont have password
          togglePassword(false);
          passwordDownload(false);
          setData(data);
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, [id, setData, togglePassword, passwordDownload]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  setTimeout(() => {
    if (document.querySelector(".password-container") !== null) {
      document.querySelector(".password-container").style = "display: block";
    }
  }, 100);

  // Show bottom section on scroll
  document.addEventListener("scroll", function () {
    const myObserver = new IntersectionObserver((elements) => {
      if (elements[0].intersectionRatio !== 0) {
        try {
          document.querySelector(".download-pulse-container").style =
            "visibility: hidden; opacity: 0";
          document.querySelector(".apk-navbar").style =
            "visibility: hidden; opacity: 0";
          document.querySelector(".view-files-container").style =
            "visibility: hidden; opacity: 0";
          document.querySelector(".transfering-section").style =
            "background-color: rgba(0, 0, 0, 0.385);";
        } catch {}
      } else {
        try {
          document.querySelector(".download-pulse-container").style =
            "visibility: visible; opacity: 1";
          document.querySelector(".apk-navbar").style =
            "visibility: visible; opacity: 1";
          document.querySelector(".view-files-container").style =
            "visibility: visible; opacity: 1";
          document.querySelector(".transfering-section").style = "transparent";
        } catch {}
      }
    });

    const myEl = document.querySelector(".download-part");
    myObserver.observe(myEl);
  });

  // Download Files
  function downloadFiles() {
    document.querySelector(".pulse-animation").style = "pointer-events: none;";
    document.body.style.cursor = "wait";

    if (downloadWithPassword === true) {
      fetch(process.env.REACT_APP_API_URL + "/container/" + id + "/download", {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: "1234",
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "filename");
            document.body.appendChild(link);
            link.click();
            document.body.style.cursor = "default";
          });
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    } else {
      fetch(
        process.env.REACT_APP_API_URL + "/container/" + id + "/download"
      ).then((response) => {
        const fileName = `Soundproof-${id}.zip`;
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.click();
          document.body.style.cursor = "default";
        });
      });
    }
  }

  const handleConvertSize = useCallback(() => {
    if (data !== null) {
      const totalSize = data.files.reduce(
        (accumulator, currentValue) => accumulator + currentValue.file_size,
        0
      );

      ConvertSize(totalSize, setSize, setExtension);
    }
  }, [data, setSize, setExtension]);

  useEffect(() => {
    handleConvertSize();
  }, [handleConvertSize]);

  return (
    <div>
      <section className="transfering-section">
        {hasPassword === true ? (
          <div className="password-container">
            <img src={DarkLogo} alt="Logo" />
            <h5>Enter password</h5>
            <p>You need a password to download this transfer</p>
            <div className="settings-pass-wrapper">
              <input
                placeholder="Password"
                className="settings-password"
                type={showPassword === false ? "password" : "text"}
              />
              <img
                onClick={togglePasswordVisibility}
                src={showPassword === false ? EyeIcon : EyeIcon2}
                alt="Eye"
              />
            </div>
            <div className="line"></div>
            <a onClick={checkPassword} className="continue-btn" href="#">
              Continue
            </a>
          </div>
        ) : (
          <div className="pulse-container download-pulse-container">
            <div className="logo-tooltip">
              <p>Click to download</p>
              <span className="tooltip-part files-size-tooltip">
                {size} {extension} - {data.files.length}{" "}
                {data.files.length === 1 ? "file" : "files"}
              </span>
              <CountDown endDate={data.expire_date}></CountDown>
              <div className="tooltip-arrow"></div>
            </div>
            <div onClick={downloadFiles} className="pulse-animation"></div>
          </div>
        )}
        {hasPassword === false ? (
          <div className="view-files-container">
            <div className="pulse-animation-scnd"></div>
            <p className="view-files-scroll">View files</p>
            <div className="view-files-line"></div>
          </div>
        ) : (
          ""
        )}
      </section>
      {hasPassword === false ? (
        <div className="download-part">
          <div className="download-files-wrapper">
            {data === null ? (
              <span className="loader"></span>
            ) : (
              data.files.map((d, i) => {
                return <File key={i} data={d}></File>;
              })
            )}
          </div>
          <div className="bottom-download-part">
            <p>
              <span>
                {data.files.length} {data.files.length === 1 ? "file" : "files"}
              </span>{" "}
              <span>
                ({size} {extension})
              </span>
            </p>
            <a
              onClick={(e) => {
                downloadFiles();
                e.preventDefault();
              }}
              className="download-btn"
              href="#"
            >
              Download all
            </a>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
