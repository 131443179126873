import NavbarLogo from "../static/images/navbar_logo.svg";

export default function Navbar() {
  function showLoader() {
    document.getElementById("loader").style.display = "block";
  }

  function hideLoader() {
    document.getElementById("loader").style.display = "none";
  }
  // Toggling button animation and toggling blur
  function showAnimation() {
    document.querySelector(".div2").classList.toggle("hide");
    document.querySelector(".div2").classList.toggle("clear");

    document.querySelector(".div1").classList.toggle("rotate-first");
    document.querySelector(".div1").classList.toggle("clear");

    document.querySelector(".div3").classList.toggle("rotate-scnd");
    document.querySelector(".div3").classList.toggle("clear");

    document.querySelector(".main-btn-section").classList.toggle("toggleBlur");
    document.querySelector(".main-btn-section").classList.toggle("clear");

    document.querySelector(".logo-link").classList.toggle("toggleBlur");
    document.querySelector(".logo-link").classList.toggle("clear");

    document.querySelector(".apk-navbar").classList.toggle("navbar-border");
    document.querySelector(".apk-navbar").classList.toggle("default-border");
  }

  // Close modal and blur
  // document.addEventListener("click", function (event) {
  //   if (
  //     document
  //       .querySelector(".main-btn-section")
  //       .classList.contains("toggleBlur") &&
  //     !event.target.classList.contains("close-wrapper") &&
  //     !event.target.classList.contains("close-lines")
  //   ) {
  //     showAnimation();
  //   }
  // });

  return (
    <nav className="apk-navbar default-border">
      <div id="circle" onMouseDown={showLoader} onMouseUp={hideLoader}>
        <div id="loader"></div>
      </div>
      <a className="logo-link clear" href="/">
        <img className="nav-logo" src={NavbarLogo} alt="Logo" />
      </a>
      {/* <div onClick={showAnimation} className="close-wrapper">
        <div className="close-lines div1 clear"></div>
        <div className="close-lines div2 clear"></div>
        <div className="close-lines div3 clear"></div>
      </div> */}
    </nav>
  );
}
