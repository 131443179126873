import { useState } from "react";
import XIcon from "../static/images/x_icon.svg";

export default function ContactMail(props) {
  const [isVisible, setVisibility] = useState(true);

  function toggleVisibility() {
    setVisibility(false);
  }

  if (isVisible === true) {
    return (
      <p key={props.mail}>
        {props.mail} <img onClick={toggleVisibility} src={XIcon}></img>
      </p>
    );
  }
}
