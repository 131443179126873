import { useState, useEffect } from "react";
import ConvertSize from "../functions/ConvertSize";
import FileIcon from "../static/images/file_icon.svg";
import FolderIcon from "../static/images/folder_icon.svg";
import XIcon from "../static/images/x_icon.svg";

export default function AddedFileFolder(props) {
  let [size, setSize] = useState(0);
  let [extension, setExtension] = useState(0);
  let bytes = props.fileType === "file" ? props.fileSize : 0;

  // Counting sizes
  if (props.fileType === "folder") {
    for (const f of Object.entries(props.file)) {
      if (f[1].size !== undefined) {
        bytes += f[1].size;
      }
    }
  }

  // Checking if KB, MG or GB
  useEffect(() => {
    ConvertSize(bytes, setSize, setExtension);
  }, [bytes]);

  return (
    <div className="file-row">
      {props.isFile === true ? (
        <img className="file-icon" src={FileIcon} alt="File" />
      ) : (
        <img className="file-icon folder-icon" src={FolderIcon} alt="File" />
      )}
      <div>
        <p className="file-name">
          {props.isFile !== true ? props.folderName : props.fileName}
        </p>
        <p className="file-size">
          {props.isFile !== true
            ? Object.entries(props.file).length + " files -"
            : ""}{" "}
          <span className="all-sizes">{size}</span> {extension}
        </p>
      </div>
      <img
        onClick={(e) => {
          props.removeFileFolder(
            props.fileName === undefined
              ? [props.folderName, "folder"]
              : [props.fileName, "file"]
          );
        }}
        className="x-icon"
        src={XIcon}
        alt="Remove"
      />
    </div>
  );
}
