import MainButton from "./components/MainButton";
import TransferingPage from "./pages/TransferingPage";
import Navbar from "./components/Navbar";
import "./static/style.css";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import DownloadPage from "./pages/DownloadPage";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Navbar></Navbar>
      <Routes>
        <Route exact path="/" element={<MainButton></MainButton>} />
        <Route
          path="/upload/:id"
          element={<TransferingPage></TransferingPage>}
        />
        <Route path="/download" element={<DownloadPage></DownloadPage>} />
      </Routes>
    </div>
  );
}

export default App;
